import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import './AdminDashboard.css';

const AdminDashboard = () => {
  const { currentUser, logout } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [roleRequests, setRoleRequests] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [activeTab, setActiveTab] = useState('users');
  const [searchTerm, setSearchTerm] = useState('');
  const [confirmAction, setConfirmAction] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [sendingMessage, setSendingMessage] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [showMessageForm, setShowMessageForm] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [showHtmlGuide, setShowHtmlGuide] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [uploadingCharacterSheet, setUploadingCharacterSheet] = useState(false);
  const [characterSheetError, setCharacterSheetError] = useState('');
  const [characterSheetSuccess, setCharacterSheetSuccess] = useState('');
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryFolders, setGalleryFolders] = useState([]);
  const [galleryCategories, setGalleryCategories] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [isLoadingGallery, setIsLoadingGallery] = useState(false);
  const [galleryError, setGalleryError] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);

  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' 
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';  // Replace with your actual production API URL

  useEffect(() => {
    fetchUsers();
    fetchRoleRequests();
    fetchNotifications();

    // Set up polling for new notifications
    const notificationInterval = setInterval(() => {
      fetchNotifications(true);
    }, 60000); // Check every minute

    return () => clearInterval(notificationInterval);
  }, []);

  useEffect(() => {
    if (activeTab === 'gallery' && galleryCategories.length === 0 && !isLoadingGallery) {
      fetchGalleryCategories();
    }
  }, [activeTab, galleryCategories.length, isLoadingGallery]);

  const fetchUsers = async () => {
    setLoading(true);
    setError('');
    
    try {
      console.log('Fetching users from:', `${API_URL}/admin/users`);
      const response = await fetch(`${API_URL}/admin/users`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || `Server responded with status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Users fetched successfully:', data);
      setUsers(data);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError(`Error fetching users: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const fetchRoleRequests = async () => {
    try {
      console.log('Fetching role requests from:', `${API_URL}/admin/role-requests`);
      const response = await fetch(`${API_URL}/admin/role-requests`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Error response:', errorData);
        return; // Don't set error state here to avoid overriding user fetch errors
      }
      
      const data = await response.json();
      console.log('Role requests fetched successfully:', data);
      setRoleRequests(data);
    } catch (err) {
      console.error('Error fetching role requests:', err);
      // Don't set error state here to avoid overriding user fetch errors
    }
  };

  const fetchNotifications = async (silent = false) => {
    try {
      if (!silent) setLoading(true);
      
      const response = await fetch(`${API_URL}/admin/notifications`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Error response:', errorData);
        return;
      }
      
      const data = await response.json();
      console.log('Notifications fetched successfully:', data);
      
      // Count unread notifications
      const unread = data.filter(notification => !notification.read).length;
      setUnreadNotifications(unread);
      
      setNotifications(data);
    } catch (err) {
      console.error('Error fetching notifications:', err);
    } finally {
      if (!silent) setLoading(false);
    }
  };

  const markNotificationAsRead = async (notificationId) => {
    try {
      const response = await fetch(`${API_URL}/admin/notifications/${notificationId}/read`, {
        method: 'PUT',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Error response:', errorData);
        return;
      }
      
      // Update local state
      setNotifications(notifications.map(notification => 
        notification.id === notificationId ? { ...notification, read: true } : notification
      ));
      
      // Update unread count
      setUnreadNotifications(prev => Math.max(0, prev - 1));
    } catch (err) {
      console.error('Error marking notification as read:', err);
    }
  };

  const handleUserSelect = (user) => {
    if (selectedUser && selectedUser.id === user.id) {
      // If clicking the same user, toggle the details closed
      setSelectedUser(null);
      setShowUserDetails(false);
    } else {
      // If clicking a different user, show that user's details
      setSelectedUser(user);
      setShowUserDetails(true);
    }
    setShowMessageForm(false);
    setCharacterSheetError('');
    setCharacterSheetSuccess('');
  };

  const handleBackToList = () => {
    setShowUserDetails(false);
  };

  const handleRoleChange = async (userId, newRole) => {
    try {
      const response = await fetch(`${API_URL}/admin/users/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify({ role: newRole })
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to update user role');
      }
      
      // Update local state
      setUsers(users.map(user => 
        user.id === userId ? { ...user, role: newRole } : user
      ));
      
      if (selectedUser && selectedUser.id === userId) {
        setSelectedUser({ ...selectedUser, role: newRole });
      }
      
      // If this was a role request, refresh the requests
      fetchRoleRequests();
      setError(''); // Clear any previous errors
    } catch (err) {
      console.error('Error updating user role:', err);
      setError(`Error updating user role: ${err.message}`);
    }
  };

  const handlePasswordReset = async (userId) => {
    setConfirmAction({
      type: 'resetPassword',
      userId,
      message: 'Are you sure you want to reset this user\'s password?',
      confirmText: 'Reset Password',
      action: async () => {
        try {
          const response = await fetch(`${API_URL}/admin/users/${userId}/reset-password`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': localStorage.getItem('auth_token')
            },
            body: JSON.stringify({ newPassword: 'tempPassword123' }) // Default temporary password
          });
          
          if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData.message || 'Failed to reset password');
          }
          
          setError('');
          alert('Password has been reset to: tempPassword123');
        } catch (err) {
          console.error('Error resetting password:', err);
          setError(`Error resetting password: ${err.message}`);
        } finally {
          setConfirmAction(null);
        }
      }
    });
  };

  const handleDeleteUser = async (userId) => {
    setConfirmAction({
      type: 'deleteUser',
      userId,
      message: 'Are you sure you want to delete this user? This action cannot be undone.',
      confirmText: 'Delete User',
      action: async () => {
        try {
          const response = await fetch(`${API_URL}/admin/users/${userId}`, {
            method: 'DELETE',
            headers: {
              'x-auth-token': localStorage.getItem('auth_token')
            }
          });
          
          if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData.message || 'Failed to delete user');
          }
          
          // Update local state
          setUsers(users.filter(user => user.id !== userId));
          
          if (selectedUser && selectedUser.id === userId) {
            setSelectedUser(null);
          }
          
          setError('');
        } catch (err) {
          console.error('Error deleting user:', err);
          setError(`Error deleting user: ${err.message}`);
        } finally {
          setConfirmAction(null);
        }
      }
    });
  };

  const handleApproveRequest = async (requestId, userId) => {
    try {
      // First update the user's role
      await handleRoleChange(userId, 'adventurer');
      
      // Then mark the request as approved
      const response = await fetch(`${API_URL}/admin/role-requests/${requestId}/approve`, {
        method: 'PUT',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to approve request');
      }
      
      // Update local state
      setRoleRequests(roleRequests.filter(req => req.id !== requestId));
      setError(''); // Clear any previous errors
    } catch (err) {
      console.error('Error approving request:', err);
      setError(`Error approving request: ${err.message}`);
    }
  };

  const handleDenyRequest = async (requestId) => {
    try {
      const response = await fetch(`${API_URL}/admin/role-requests/${requestId}/deny`, {
        method: 'PUT',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to deny request');
      }
      
      // Update local state
      setRoleRequests(roleRequests.filter(req => req.id !== requestId));
      setError(''); // Clear any previous errors
    } catch (err) {
      console.error('Error denying request:', err);
      setError(`Error denying request: ${err.message}`);
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    
    if (!selectedUser) {
      setMessageError('No user selected');
      return;
    }
    
    if (!messageText.trim()) {
      setMessageError('Message cannot be empty');
      return;
    }
    
    setSendingMessage(true);
    setMessageError('');
    
    try {
      // Log the request details for debugging
      console.log('Sending message to user ID:', selectedUser.id);
      console.log('Message content:', messageText);
      
      // Try the endpoint with user ID in the path
      const endpoint = `${API_URL}/admin/users/${selectedUser.id}/message`;
      console.log('Using API endpoint:', endpoint);
      
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify({
          message: messageText,
          fromAdmin: true
        })
      });
      
      // Log the response for debugging
      console.log('Response status:', response.status);
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Error response data:', errorData);
        throw new Error(errorData.message || `Failed to send message: ${response.status}`);
      }
      
      // Clear the message form
      setMessageText('');
      setShowMessageForm(false);
      alert(`Message sent to ${selectedUser.displayName}`);
    } catch (err) {
      console.error('Error sending message:', err);
      setMessageError(`Error sending message: ${err.message}`);
      
      // Show a more detailed error message to help with debugging
      alert(`Failed to send message. Error: ${err.message}\nPlease check the console for more details.`);
    } finally {
      setSendingMessage(false);
    }
  };

  const handleCharacterSheetUpload = async (e) => {
    const file = e.target.files[0];
    if (!file || !selectedUser) return;
    
    // Check file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      setCharacterSheetError('File too large. Maximum size is 5MB.');
      return;
    }
    
    // Check file type
    if (file.type !== 'application/json') {
      setCharacterSheetError('Only JSON files are allowed.');
      return;
    }
    
    setUploadingCharacterSheet(true);
    setCharacterSheetError('');
    setCharacterSheetSuccess('');
    
    try {
      // Create FormData object for file upload
      const formData = new FormData();
      formData.append('characterSheet', file);
      
      // Add user ID to the form data
      formData.append('userId', selectedUser.id);
      
      // Upload the file to the server
      console.log('Uploading character sheet for user:', selectedUser.id);
      const response = await fetch(`${API_URL}/admin/upload/character-sheet`, {
        method: 'POST',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: formData
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to upload character sheet');
      }
      
      const data = await response.json();
      console.log('Character sheet uploaded successfully:', data);
      
      setCharacterSheetSuccess('Character sheet uploaded successfully');
      
      // Clear the success message after 3 seconds
      setTimeout(() => {
        setCharacterSheetSuccess('');
      }, 3000);
    } catch (err) {
      setCharacterSheetError(err.message || 'An error occurred during upload');
      console.error('Error uploading character sheet:', err);
    } finally {
      setUploadingCharacterSheet(false);
      
      // Reset the file input
      if (e.target) {
        e.target.value = '';
      }
    }
  };

  const handleRetry = () => {
    setError('');
    setActiveTab('users');
    fetchUsers();
    fetchRoleRequests();
    fetchNotifications();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };
  
  // Function to handle inserting HTML formatting tags
  const insertFormatting = (type) => {
    // Get textarea element
    const textarea = document.querySelector('.message-form textarea');
    if (!textarea) return;
    
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = messageText.substring(start, end);
    let replacement = '';
    
    // Insert appropriate HTML based on the formatting type
    switch (type) {
      case 'bold':
        replacement = `<strong>${selectedText || 'bold text'}</strong>`;
        break;
      case 'italic':
        replacement = `<em>${selectedText || 'italic text'}</em>`;
        break;
      case 'heading':
        replacement = `<h3>${selectedText || 'Heading'}</h3>`;
        break;
      case 'paragraph':
        replacement = `<p>${selectedText || 'New paragraph'}</p>`;
        break;
      case 'blockquote':
        replacement = `<blockquote>${selectedText || 'Quoted text'}</blockquote>`;
        break;
      case 'list':
        replacement = `<ul>\n  <li>${selectedText || 'List item'}</li>\n  <li>Another item</li>\n</ul>`;
        break;
      default:
        return;
    }
    
    // Insert the HTML at the cursor position or replace selected text
    const newText = messageText.substring(0, start) + replacement + messageText.substring(end);
    setMessageText(newText);
    
    // Focus back on textarea after inserting
    setTimeout(() => {
      textarea.focus();
      textarea.setSelectionRange(start + replacement.length, start + replacement.length);
    }, 0);
  };

  // Filter users based on search term
  const filteredUsers = users.filter(user => 
    user.displayName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.role?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleLogout = () => {
    logout();
  };

  const fetchGalleryCategories = async () => {
    try {
      setIsLoadingGallery(true);
      setGalleryError('');
      
      const API_URL = window.location.hostname === 'localhost' 
        ? 'http://localhost:3001/api'
        : 'https://firstveil.com/api';
      
      const response = await fetch(`${API_URL}/gallery/categories`, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        setGalleryCategories(data.categories);
      } else {
        setGalleryError('Failed to load gallery categories');
      }
    } catch (error) {
      console.error('Error fetching gallery categories:', error);
      setGalleryError('An error occurred while loading the gallery');
    } finally {
      setIsLoadingGallery(false);
    }
  };

  const fetchFolderImages = async (category, folderPath) => {
    try {
      setIsLoadingGallery(true);
      setGalleryError('');
      
      console.log(`Fetching images from: category=${category}, folder=${folderPath}`);
      
      const API_URL = window.location.hostname === 'localhost' 
        ? 'http://localhost:3001/api'
        : 'https://firstveil.com/api';
      
      const response = await fetch(`${API_URL}/gallery/folder/${category}/${folderPath}`, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        console.log(`Received ${data.images.length} images:`, data.images);
        setGalleryImages(data.images);
        setCurrentFolder({ category, path: folderPath });
      } else {
        const errorData = await response.text();
        console.error('Failed to load folder images:', errorData);
        setGalleryError(`Failed to load folder images: ${errorData}`);
      }
    } catch (error) {
      console.error('Error fetching folder images:', error);
      setGalleryError('An error occurred while loading the images');
    } finally {
      setIsLoadingGallery(false);
    }
  };

  const handleBackToFolders = () => {
    setCurrentFolder(null);
    setGalleryImages([]);
  };

  const handleFolderClick = (category, folder) => {
    fetchFolderImages(category, folder.path);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="admin-dashboard">
      {/* Header with logout button */}
      <div className="dashboard-header">
        <h1>First Veil</h1>
        <button 
          className="logout-button"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>

      <div className="dashboard-tabs">
        <button 
          className={`tab-button ${activeTab === 'users' ? 'active' : ''}`}
          onClick={() => setActiveTab('users')}
        >
          User Management
        </button>
        <button 
          className={`tab-button ${activeTab === 'requests' ? 'active' : ''}`}
          onClick={() => setActiveTab('requests')}
        >
          Role Requests {roleRequests.length > 0 && <span className="request-badge">{roleRequests.length}</span>}
        </button>
        <button 
          className={`tab-button ${activeTab === 'notifications' ? 'active' : ''}`}
          onClick={() => setActiveTab('notifications')}
        >
          Notifications
        </button>
        <button 
          className={`tab-button ${activeTab === 'gallery' ? 'active' : ''}`}
          onClick={() => setActiveTab('gallery')}
        >
          Gallery
        </button>
        <button 
          className={`tab-button ${activeTab === 'links' ? 'active' : ''}`}
          onClick={() => setActiveTab('links')}
        >
          Links
        </button>
      </div>
      
      {error && (
        <div className="admin-error">
          <p>{error}</p>
          <button className="retry-button" onClick={handleRetry}>Retry</button>
        </div>
      )}
      
      <div className="tab-content">
        {activeTab === 'users' && (
          <div className="users-tab">
            <div className="users-list-container">
              <div className="search-bar">
                <input
                  type="text"
                  placeholder="Search users..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              
              {loading ? (
                <div className="loading-users">
                  <div className="loading-spinner"></div>
                  <p>Loading users...</p>
                </div>
              ) : (
                <div className="users-list">
                  {filteredUsers.length > 0 ? (
                    filteredUsers.map(user => (
                      <div key={user.id}>
                        <div 
                          className={`user-item ${selectedUser && selectedUser.id === user.id ? 'selected' : ''}`}
                          onClick={() => handleUserSelect(user)}
                        >
                          <div className="user-avatar">
                            {user.avatar ? (
                              <img src={user.avatar} alt={user.displayName} />
                            ) : (
                              <div className="avatar-placeholder">
                                {user.displayName?.charAt(0).toUpperCase() || 'U'}
                              </div>
                            )}
                          </div>
                          <div className="user-info">
                            <div className="user-name">{user.displayName}</div>
                            <div className="user-email">{user.email}</div>
                          </div>
                          <div className={`user-role role-${user.role}`}>
                            {user.role}
                          </div>
                        </div>
                        
                        {/* User details panel rendered directly under the selected user */}
                        {selectedUser && selectedUser.id === user.id && showUserDetails && (
                          <div className="user-details-inline">
                            <div className="detail-item">
                              <div className="detail-label">Email</div>
                              <div className="detail-value">{selectedUser.email}</div>
                            </div>
                            <div className="detail-item">
                              <div className="detail-label">User ID</div>
                              <div className="detail-value">{selectedUser.id}</div>
                            </div>
                            <div className="detail-item">
                              <div className="detail-label">Role</div>
                              <div className="role-selector">
                                <select 
                                  value={selectedUser.role} 
                                  onChange={(e) => handleRoleChange(selectedUser.id, e.target.value)}
                                >
                                  <option value="commoner">Commoner</option>
                                  <option value="adventurer">Adventurer</option>
                                  <option value="admin">Admin</option>
                                </select>
                              </div>
                            </div>
                            <div className="detail-item">
                              <div className="detail-label">Character Sheet</div>
                              <div className="detail-value">
                                <label className="action-button">
                                  Upload Character Sheet
                                  <input 
                                    type="file" 
                                    accept="application/json" 
                                    onChange={handleCharacterSheetUpload} 
                                    disabled={uploadingCharacterSheet || selectedUser.role !== 'adventurer'}
                                    style={{ display: 'none' }}
                                  />
                                </label>
                                {uploadingCharacterSheet && <span className="loading-spinner-small"></span>}
                                {selectedUser.role !== 'adventurer' && (
                                  <div className="character-sheet-note">
                                    User must be an Adventurer to have a character sheet
                                  </div>
                                )}
                                {characterSheetError && (
                                  <div className="message-error">{characterSheetError}</div>
                                )}
                                {characterSheetSuccess && (
                                  <div className="message-success">{characterSheetSuccess}</div>
                                )}
                              </div>
                            </div>
                            <div className="detail-item">
                              <div className="detail-label">Member Since</div>
                              <span className="detail-value">{formatDate(selectedUser.createdAt)}</span>
                            </div>
                            
                            <div className="user-actions">
                              <button 
                                className="action-button reset-password"
                                onClick={() => handlePasswordReset(selectedUser.id)}
                              >
                                Reset Password
                              </button>
                              <button 
                                className="action-button delete-user"
                                onClick={() => handleDeleteUser(selectedUser.id)}
                              >
                                Delete User
                              </button>
                              <button 
                                className="action-button message-user"
                                onClick={() => setShowMessageForm(true)}
                              >
                                Send Message
                              </button>
                            </div>
                            
                            {/* Message section with proper conditional rendering */}
                            {showMessageForm && (
                              <div className="message-section">
                                <form onSubmit={handleSendMessage} className="message-form">
                                  <h4>Send Message to {selectedUser.displayName}</h4>
                                  {messageError && <div className="message-error">{messageError}</div>}
                                  
                                  <textarea
                                    value={messageText}
                                    onChange={(e) => setMessageText(e.target.value)}
                                    placeholder="Enter your message here... HTML formatting is supported."
                                    rows={8}
                                    required
                                  />
                                  <div className="message-preview">
                                    <h5>Preview:</h5>
                                    <div 
                                      className="preview-content"
                                      dangerouslySetInnerHTML={{ __html: messageText }}
                                    />
                                  </div>
                                  <div className="message-actions">
                                    <button 
                                      type="submit" 
                                      className="send-message-btn"
                                      disabled={sendingMessage}
                                    >
                                      {sendingMessage ? 'Sending...' : 'Send Message'}
                                    </button>
                                    <button 
                                      type="button" 
                                      className="cancel-message-btn"
                                      onClick={() => {
                                        setShowMessageForm(false);
                                        setMessageText('');
                                        setMessageError('');
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </form>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="no-users">No users found</div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        
        {activeTab === 'requests' && (
          <div className="requests-tab">
            <h3>Adventurer Role Requests</h3>
            
            {loading ? (
              <div className="loading-requests">
                <div className="loading-spinner"></div>
                <p>Loading requests...</p>
              </div>
            ) : roleRequests.length > 0 ? (
              <div className="requests-list">
                {roleRequests.map(request => (
                  <div key={request.id} className="request-item">
                    <div className="request-header">
                      <div className="requester-info">
                        <span className="requester-name">{request.userName}</span>
                        <span className="requester-email">{request.userEmail}</span>
                      </div>
                      <div className="request-date">{formatDate(request.createdAt)}</div>
                    </div>
                    
                    <div className="request-reason">
                      <h4>Reason for Request:</h4>
                      <p>{request.reason}</p>
                    </div>
                    
                    <div className="request-actions">
                      <button 
                        className="action-button approve"
                        onClick={() => handleApproveRequest(request.id, request.userId)}
                      >
                        Approve
                      </button>
                      <button 
                        className="action-button deny"
                        onClick={() => handleDenyRequest(request.id)}
                      >
                        Deny
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-requests">
                <p>No pending role requests</p>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'notifications' && (
          <div className="notifications-tab">
            <h3>Notifications</h3>
            
            {loading ? (
              <div className="loading-notifications">
                <div className="loading-spinner"></div>
                <p>Loading notifications...</p>
              </div>
            ) : notifications.length > 0 ? (
              <div className="notifications-list">
                {notifications.map(notification => (
                  <div key={notification.id} className="notification-item">
                    <div className="notification-header">
                      <div className="notification-title">{notification.title}</div>
                      <div className="notification-date">{formatDate(notification.createdAt)}</div>
                    </div>
                    <div className="notification-content">
                      <p>{notification.content}</p>
                    </div>
                    <div className="notification-actions">
                      <button 
                        className="action-button mark-read"
                        onClick={() => markNotificationAsRead(notification.id)}
                      >
                        Mark as Read
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-notifications">
                <p>No notifications available</p>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'gallery' && (
          <div className="gallery-tab">
            <h3>Image Gallery</h3>
            
            {isLoadingGallery ? (
              <div className="loading-gallery">
                <p>Loading gallery...</p>
              </div>
            ) : galleryError ? (
              <div className="gallery-error">
                <p>{galleryError}</p>
                <button onClick={currentFolder ? () => fetchFolderImages(currentFolder.category, currentFolder.path) : fetchGalleryCategories} className="retry-button">
                  Retry
                </button>
              </div>
            ) : currentFolder ? (
              <>
                <div className="gallery-header">
                  <button className="back-button" onClick={handleBackToFolders}>
                    &larr; Back to Categories
                  </button>
                  <h4>{currentFolder.category.charAt(0).toUpperCase() + currentFolder.category.slice(1)} / {currentFolder.path}</h4>
                </div>
                
                {galleryImages.length === 0 ? (
                  <div className="no-images">
                    <p>No images found in this folder.</p>
                  </div>
                ) : (
                  <div className="gallery-grid">
                    {galleryImages.map((image, index) => {
                      console.log(`Rendering image ${index}:`, image.url);
                      return (
                        <div 
                          key={`image-${index}`} 
                          className="gallery-item"
                          onClick={() => handleImageClick(image)}
                        >
                          <img 
                            src={image.url} 
                            alt={image.name} 
                            onLoad={() => console.log(`Image loaded successfully: ${image.url}`)}
                            onError={(e) => {
                              console.error(`Failed to load image: ${image.url}`);
                              e.target.src = '/placeholder-image.svg';
                              e.target.alt = 'Image not found';
                            }} 
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            ) : (
              galleryCategories.length === 0 ? (
                <div className="no-folders">
                  <p>No categories available in the gallery.</p>
                </div>
              ) : (
                <>
                  <div className="gallery-categories">
                    {galleryCategories.map(category => {
                      if (category.folders.length === 0) return null;
                      
                      return (
                        <div key={category.path} className="gallery-category">
                          <h4>{category.name}</h4>
                          <div className="folders-grid">
                            {category.folders.map((folder, index) => (
                              <div 
                                key={`folder-${index}`} 
                                className="folder-item"
                                onClick={() => handleFolderClick(category.path, folder)}
                              >
                                <div className="folder-icon">
                                  <i className="folder-symbol">📁</i>
                                </div>
                                <div className="folder-name">{folder.name}</div>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )
            )}
            
            {selectedImage && (
              <div className="image-modal-overlay" onClick={handleCloseModal}>
                <div className="image-modal" onClick={e => e.stopPropagation()}>
                  <div className="modal-header">
                    <h3>{selectedImage.name}</h3>
                    <div className="modal-actions">
                      <a 
                        className="download-button" 
                        href={selectedImage.url} 
                        download={selectedImage.name}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download
                      </a>
                      <button className="close-button" onClick={handleCloseModal}>
                        Close
                      </button>
                    </div>
                  </div>
                  <div className="modal-body">
                    <img 
                      src={selectedImage.url} 
                      alt={selectedImage.name} 
                      onError={(e) => {
                        console.error(`Failed to load image in modal: ${selectedImage.url}`);
                        e.target.src = '/placeholder-image.svg';
                        e.target.alt = 'Image not found';
                      }} 
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'links' && (
          <div className="links-tab">
            {/* ... existing links tab content ... */}
          </div>
        )}
      </div>
      
      {confirmAction && (
        <div className="confirm-modal">
          <div className="confirm-content">
            <h3>Confirm Action</h3>
            <p>{confirmAction.message}</p>
            <div className="confirm-buttons">
              <button 
                className="confirm-button"
                onClick={confirmAction.action}
              >
                {confirmAction.confirmText}
              </button>
              <button 
                className="cancel-button"
                onClick={() => setConfirmAction(null)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard; 