import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import './AdventurerDashboard.css';

const AdventurerDashboard = ({ messages, lastSession }) => {
  const { currentUser, logout } = useAuth();
  const [activeTab, setActiveTab] = useState('overview');
  const [avatarUrl, setAvatarUrl] = useState(currentUser?.avatar || '');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [characterSheet, setCharacterSheet] = useState(null);
  const [isLoadingCharacter, setIsLoadingCharacter] = useState(false);
  const [characterError, setCharacterError] = useState('');
  const [isMounted, setIsMounted] = useState(true);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryFolders, setGalleryFolders] = useState([]);
  const [galleryCategories, setGalleryCategories] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [isLoadingGallery, setIsLoadingGallery] = useState(false);
  const [galleryError, setGalleryError] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  
  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' 
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';  // Replace with your actual production API URL
  
  // Fetch avatar when component mounts
  useEffect(() => {
    setIsMounted(true);
    if (isMounted) {
      fetchAvatar();
      // If user is adventurer, fetch character sheet
      if (currentUser?.role === 'adventurer') {
        fetchCharacterSheet();
      }
    }

    return () => {
      setIsMounted(false);
    };
  }, [isMounted, currentUser]);

  // Fetch gallery categories when gallery tab is selected
  useEffect(() => {
    if (activeTab === 'gallery' && galleryCategories.length === 0 && !isLoadingGallery) {
      fetchGalleryCategories();
    }
  }, [activeTab, galleryCategories.length, isLoadingGallery]);

  // Fetch user's avatar from the server
  const fetchAvatar = async () => {
    try {
      if (!currentUser || !isMounted) return;
      
      const response = await fetch(`${API_URL}/avatar`, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (response.ok && isMounted) {
        const imageBlob = await response.blob();
        const imageUrl = URL.createObjectURL(imageBlob);
        setAvatarUrl(imageUrl);
      } else {
        console.error('Failed to fetch avatar');
      }
    } catch (error) {
      console.error('Error fetching avatar:', error);
    }
  };

  // Fetch user's character sheet
  const fetchCharacterSheet = async () => {
    try {
      if (!isMounted) return;
      
      setIsLoadingCharacter(true);
      setCharacterError('');
      // Clear the current character sheet data before fetching new data
      setCharacterSheet(null);
      
      const response = await fetch(`${API_URL}/character-sheet`, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to fetch character sheet');
      }
      
      const data = await response.json();
      if (isMounted) {
        setCharacterSheet(data);
      }
    } catch (err) {
      if (isMounted) {
        console.error('Error fetching character sheet:', err);
        setCharacterError(err.message || 'An error occurred while fetching character sheet');
      }
    } finally {
      if (isMounted) {
        setIsLoadingCharacter(false);
      }
    }
  };

  // Fetch gallery categories
  const fetchGalleryCategories = async () => {
    try {
      setIsLoadingGallery(true);
      setGalleryError('');
      
      const response = await fetch(`${API_URL}/gallery/categories`, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        setGalleryCategories(data.categories);
      } else {
        setGalleryError('Failed to load gallery categories');
      }
    } catch (error) {
      console.error('Error fetching gallery categories:', error);
      setGalleryError('An error occurred while loading the gallery');
    } finally {
      setIsLoadingGallery(false);
    }
  };

  // Fetch images from a specific folder
  const fetchFolderImages = async (category, folderPath) => {
    try {
      setIsLoadingGallery(true);
      setGalleryError('');
      
      console.log(`Fetching images from: category=${category}, folder=${folderPath}`);
      
      const response = await fetch(`${API_URL}/gallery/folder/${category}/${folderPath}`, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        console.log(`Received ${data.images.length} images:`, data.images);
        setGalleryImages(data.images);
        setCurrentFolder({ category, path: folderPath });
      } else {
        const errorData = await response.text();
        console.error('Failed to load folder images:', errorData);
        setGalleryError(`Failed to load folder images: ${errorData}`);
      }
    } catch (error) {
      console.error('Error fetching folder images:', error);
      setGalleryError('An error occurred while loading the images');
    } finally {
      setIsLoadingGallery(false);
    }
  };

  // Return to folders view
  const handleBackToFolders = () => {
    setCurrentFolder(null);
    setGalleryImages([]);
  };

  // Handle folder click
  const handleFolderClick = (category, folder) => {
    fetchFolderImages(category, folder.path);
  };

  // Calculate ability modifier
  const getAbilityModifier = (score) => {
    if (!score) return 0;
    return Math.floor((score - 10) / 2);
  };

  // Render HTML content safely
  const renderHtml = (html) => {
    return { __html: html };
  };

  // Handle avatar upload
  const handleAvatarUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    // Check file size (max 2MB)
    if (file.size > 2 * 1024 * 1024) {
      setUploadError('File too large. Maximum size is 2MB.');
      return;
    }
    
    // Check file type
    if (!file.type.match('image.*')) {
      setUploadError('Only image files are allowed.');
      return;
    }
    
    setIsUploading(true);
    setUploadError('');
    
    try {
      const formData = new FormData();
      formData.append('avatar', file);
      
      const response = await fetch(`${API_URL}/avatar/upload`, {
        method: 'POST',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: formData
      });
      
      if (!response.ok) {
        throw new Error('Failed to upload avatar');
      }
      
      // Fetch the new avatar
      await fetchAvatar();
      
    } catch (err) {
      console.error('Error uploading avatar:', err);
      setUploadError('Failed to upload avatar');
    } finally {
      setIsUploading(false);
      e.target.value = '';
    }
  };

  // Format date string
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  // Handle tab selection
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Handle logout
  const handleLogout = () => {
    logout();
  };

  // Function to refresh character sheet data
  const refreshCharacterSheet = async () => {
    setIsLoadingCharacter(true);
    setCharacterSheet(null);
    setCharacterError('');
    await fetchCharacterSheet();
  };

  // Handle character sheet upload
  const handleCharacterSheetUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Check file type
    if (!file.name.endsWith('.json')) {
      setCharacterError('Invalid file type. Please upload a JSON file.');
      return;
    }

    // Check file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      setCharacterError('File too large. Maximum size is 5MB.');
      return;
    }

    setIsLoadingCharacter(true);
    setCharacterError('');
    // Clear the current character sheet data before uploading new data
    setCharacterSheet(null);
    
    // Read the file contents first to ensure it's valid JSON
    try {
      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          // Validate JSON first
          const fileContent = event.target.result;
          const parsedData = JSON.parse(fileContent); 
          
          // Basic validation to check if it's a character sheet
          if (!parsedData.name) {
            throw new Error('Invalid character sheet format: Missing character name');
          }
          
          // If JSON is valid, proceed with the upload
          const formData = new FormData();
          formData.append('characterSheet', file);

          const response = await fetch(`${API_URL}/adventurer/upload/character-sheet`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: formData
          });

          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to upload character sheet');
          }

          // Successfully uploaded, now fetch the character sheet
          await fetchCharacterSheet();
        } catch (error) {
          console.error('Error processing or uploading character sheet:', error);
          setCharacterError(error.message || 'Invalid JSON file or failed to upload character sheet');
          setIsLoadingCharacter(false);
        }
      };
      
      reader.onerror = () => {
        setCharacterError('Error reading the file');
        setIsLoadingCharacter(false);
      };
      
      reader.readAsText(file);
    } catch (error) {
      console.error('Error uploading character sheet:', error);
      setCharacterError(error.message || 'Failed to upload character sheet');
      setIsLoadingCharacter(false);
    }
  };

  // Render character sheet content
  const renderCharacterSheet = () => {
    if (isLoadingCharacter) {
      return <div className="loading-character">Loading character sheet...</div>;
    }

    if (characterError) {
      return <div className="character-error">{characterError}</div>;
    }

    if (!characterSheet) {
      return (
        <div className="no-character-sheet">
          <h4>No Character Sheet Found</h4>
          <p>Upload your character sheet to see it displayed here.</p>
          <label className="upload-character-button">
            Upload Character Sheet
            <input
              type="file"
              accept=".json"
              onChange={handleCharacterSheetUpload}
            />
          </label>
        </div>
      );
    }

    // Create a string-based representation of the character sheet to avoid DOM conflicts
    const renderCharacterContent = () => {
      if (!characterSheet) return ''; // Added safeguard
      
      let html = '';
      
      // Top header with character name and portrait if available
      html += `<div class="character-header">
        <h2 class="character-name">${characterSheet.name || 'Unnamed Character'}</h2>
        ${characterSheet.img ? `<div class="character-portrait"><img src="${characterSheet.img}" alt="${characterSheet.name}" /></div>` : ''}
      </div>`;
      
      // add debugging info to help understand the structure
      // This will be hidden by default but can be shown with a toggle
      html += `<details class="debug-info">
        <summary>Debug JSON Structure</summary>
        <pre>${JSON.stringify(characterSheet, null, 2)}</pre>
      </details>`;
      
      // Character details section - EXPANDED with ALL details from JSON
      html += '<div class="character-details-section">';
      html += '<h3>Character Details</h3>';
      html += '<div class="character-details">';
      
      // Basic info row 1
      const detailsToShow = [
        { label: 'Class', value: characterSheet.system?.details?.class },
        { label: 'Level', value: characterSheet.system?.details?.level },
        { label: 'Race', value: characterSheet.system?.details?.race },
        { label: 'Background', value: characterSheet.system?.details?.background },
        { label: 'Alignment', value: characterSheet.system?.details?.alignment },
        { label: 'XP', value: characterSheet.system?.details?.xp?.value }
      ];
      
      detailsToShow.forEach(detail => {
        html += `
          <div class="character-detail">
            <span class="detail-label">${detail.label}</span>
            <span class="detail-value">${detail.value || 'Unknown'}</span>
          </div>
        `;
      });
      
      // Additional character info
      if (characterSheet.system?.details) {
        const details = characterSheet.system.details;
        Object.entries(details).forEach(([key, value]) => {
          // Skip the ones we already displayed
          if (['class', 'level', 'race', 'background', 'alignment', 'xp'].includes(key)) return;
          
          // Skip complex objects or empty values
          if (typeof value === 'object' || value === null || value === undefined || value === '') return;
          
          html += `
            <div class="character-detail">
              <span class="detail-label">${key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}</span>
              <span class="detail-value">${value}</span>
            </div>
          `;
        });
      }
      
      html += '</div>'; // End character details
      html += '</div>'; // End character details section
      
      // Abilities Section - EXPANDED
      html += '<div class="abilities-section">';
      html += '<h3>Abilities</h3>';
      html += '<div class="abilities-container">';
      
      if (characterSheet.system?.abilities) {
        Object.entries(characterSheet.system.abilities).forEach(([key, ability]) => {
          const mod = getAbilityModifier(ability.value);
          const sign = mod >= 0 ? '+' : '';
          
          html += `
            <div class="ability-score">
              <div class="ability-name">${key.toUpperCase()}</div>
              <div class="ability-value">${ability.value !== undefined && ability.value !== null ? ability.value : ''}</div>
              <div class="ability-modifier">${sign}${mod}</div>
              ${ability.proficient ? '<div class="ability-proficient">Proficient</div>' : ''}
              ${ability.saveBonus ? `<div class="ability-save">Save: ${ability.saveBonus}</div>` : ''}
            </div>
          `;
        });
      }
      
      html += '</div>'; // End abilities container
      html += '</div>'; // End abilities section
      
      // Combat Stats Section - EXPANDED with all stats
      html += '<div class="combat-section">';
      html += '<h3>Combat</h3>';
      html += '<div class="combat-stats">';
      
      // HP section
      if (characterSheet.system?.attributes?.hp) {
        const hp = characterSheet.system.attributes.hp;
        const hpPercentage = Math.min(100, Math.max(0, ((hp.value || 0) / (hp.max || 1)) * 100));
        
        html += `
          <div class="combat-stat hp-container">
            <div class="stat-name">Hit Points</div>
            <div class="hp-values">
              <span class="current-hp">${hp.value || 0}</span>
              <span class="hp-separator">/</span>
              <span class="max-hp">${hp.max || 0}</span>
            </div>
            <div class="hp-bar">
              <div class="hp-fill" style="width: ${hpPercentage}%"></div>
              <div class="hp-text">${hp.value || 0} / ${hp.max || 0}</div>
            </div>
            ${hp.temp ? `<div class="temp-hp">Temp HP: ${hp.temp}</div>` : ''}
          </div>
        `;
      }
      
      // Core combat stats
      const combatStats = [
        { name: 'Armor Class', value: characterSheet.system?.attributes?.ac?.value !== undefined ? characterSheet.system?.attributes?.ac?.value : '' },
        { name: 'Initiative', value: characterSheet.system?.attributes?.init?.total !== undefined ? characterSheet.system?.attributes?.init?.total : '' },
        { name: 'Speed', value: characterSheet.system?.attributes?.movement?.walk !== undefined ? `${characterSheet.system?.attributes?.movement?.walk} ft` : '' },
        { name: 'Proficiency', value: characterSheet.system?.attributes?.prof?.value !== undefined ? characterSheet.system?.attributes?.prof?.value : '' }
      ];
      
      combatStats.forEach(stat => {
        html += `
          <div class="combat-stat">
            <div class="stat-name">${stat.name}</div>
            <div class="stat-value">${stat.value}</div>
          </div>
        `;
      });
      
      // Add other movement types if they exist
      if (characterSheet.system?.attributes?.movement) {
        const movement = characterSheet.system.attributes.movement;
        Object.entries(movement).forEach(([key, value]) => {
          if (key !== 'walk' && value) {
            html += `
              <div class="combat-stat">
                <div class="stat-name">${key.charAt(0).toUpperCase() + key.slice(1)} Speed</div>
                <div class="stat-value">${value} ft</div>
              </div>
            `;
          }
        });
      }
      
      // Add hit dice if they exist
      if (characterSheet.system?.attributes?.hd) {
        const hd = characterSheet.system.attributes.hd;
        html += `
          <div class="combat-stat">
            <div class="stat-name">Hit Dice</div>
            <div class="stat-value">${hd.value || 0}/${hd.max || 0} ${hd.die || 'd8'}</div>
          </div>
        `;
      }
      
      // Add death saves if they exist
      if (characterSheet.system?.attributes?.death) {
        const death = characterSheet.system.attributes.death;
        html += `
          <div class="combat-stat death-saves">
            <div class="stat-name">Death Saves</div>
            <div class="death-save-boxes">
              <div class="success-saves">
                <span>Successes: </span>
                <span class="save-box ${death.success >= 1 ? 'filled' : ''}"></span>
                <span class="save-box ${death.success >= 2 ? 'filled' : ''}"></span>
                <span class="save-box ${death.success >= 3 ? 'filled' : ''}"></span>
              </div>
              <div class="failure-saves">
                <span>Failures: </span>
                <span class="save-box ${death.failure >= 1 ? 'filled' : ''}"></span>
                <span class="save-box ${death.failure >= 2 ? 'filled' : ''}"></span>
                <span class="save-box ${death.failure >= 3 ? 'filled' : ''}"></span>
              </div>
            </div>
          </div>
        `;
      }
      
      html += '</div>'; // End combat stats
      html += '</div>'; // End combat section
      
      // Skills Section - EXPANDED with all skills and details
      html += '<div class="skills-section">';
      html += '<h3>Skills</h3>';
      html += '<div class="skills-container">';
      
      if (characterSheet.system) {
        // Calculate proficiency bonus based on level
        const level = characterSheet.system?.details?.level || 1;
        const proficiencyBonus = Math.floor((level - 1) / 4) + 2;
        
        // Define skills in D&D character sheet order with full names
        const dndSkillOrder = [
          { key: 'acr', name: 'Acrobatics', ability: 'dex' },
          { key: 'ani', name: 'Animal Handling', ability: 'wis' },
          { key: 'arc', name: 'Arcana', ability: 'int' },
          { key: 'ath', name: 'Athletics', ability: 'str' },
          { key: 'dec', name: 'Deception', ability: 'cha' },
          { key: 'his', name: 'History', ability: 'int' },
          { key: 'ins', name: 'Insight', ability: 'wis' },
          { key: 'itm', name: 'Intimidation', ability: 'cha' },
          { key: 'inv', name: 'Investigation', ability: 'int' },
          { key: 'med', name: 'Medicine', ability: 'wis' },
          { key: 'nat', name: 'Nature', ability: 'int' },
          { key: 'prc', name: 'Perception', ability: 'wis' },
          { key: 'prf', name: 'Performance', ability: 'cha' },
          { key: 'per', name: 'Persuasion', ability: 'cha' },
          { key: 'rel', name: 'Religion', ability: 'int' },
          { key: 'slt', name: 'Sleight of Hand', ability: 'dex' },
          { key: 'ste', name: 'Stealth', ability: 'dex' },
          { key: 'sur', name: 'Survival', ability: 'wis' }
        ];
        
        // Render skills in the specified order
        dndSkillOrder.forEach(orderedSkill => {
          // Get the skill data from the character sheet
          const skills = characterSheet.system.skills || {};
          const skill = skills[orderedSkill.key];
          
          // Get the corresponding ability score
          const abilityKey = orderedSkill.ability;
          const abilityScore = characterSheet.system.abilities?.[abilityKey]?.value || 10;
          const abilityMod = getAbilityModifier(abilityScore);
          
          if (skill) {
            // Calculate the skill modifier
            // value: 0 = not proficient, 1 = proficient, 2 = expertise
            const profValue = skill.value || 0;
            const isProficient = profValue > 0;
            const hasExpertise = profValue > 1;
            
            // Calculate the total modifier: ability modifier + (proficiency value × proficiency bonus)
            const totalMod = abilityMod + (profValue * proficiencyBonus);
            const formattedMod = totalMod >= 0 ? `+${totalMod}` : `${totalMod}`;
            
            // Display the ability name in uppercase
            const displayAbility = abilityKey.toUpperCase();
            
            html += `
              <div class="skill-item">
                <div class="skill-proficient ${isProficient ? 'active' : ''}"></div>
                <div class="skill-name">${orderedSkill.name} (${displayAbility})</div>
                <div class="skill-modifier">${formattedMod}</div>
                ${hasExpertise ? '<div class="skill-expertise">Expertise</div>' : ''}
              </div>
            `;
          } else {
            // If skill is not defined, just show the base ability modifier
            const formattedMod = abilityMod >= 0 ? `+${abilityMod}` : `${abilityMod}`;
            const displayAbility = abilityKey.toUpperCase();
            
            html += `
              <div class="skill-item">
                <div class="skill-proficient"></div>
                <div class="skill-name">${orderedSkill.name} (${displayAbility})</div>
                <div class="skill-modifier">${formattedMod}</div>
              </div>
            `;
          }
        });
      } else {
        // If no skills at all, create a placeholder
        html += '<div class="no-items">No skills found</div>';
      }
      
      html += '</div>'; // End skills container
      html += '</div>'; // End skills section
      
      // Proficiencies Section - Tools, languages, etc.
      if (characterSheet.system?.traits) {
        html += '<div class="proficiencies-section">';
        html += '<h3>Proficiencies & Languages</h3>';
        
        const traits = characterSheet.system.traits;
        
        // Languages
        if (traits.languages) {
          html += '<div class="proficiency-group">';
          html += '<h4>Languages</h4>';
          html += '<div class="proficiency-list">';
          
          if (traits.languages.value && traits.languages.value.length > 0) {
            traits.languages.value.forEach(lang => {
              html += `<div class="proficiency-item">${lang}</div>`;
            });
          } else {
            html += '<div class="proficiency-item">None</div>';
          }
          
          html += '</div>'; // End proficiency list
          html += '</div>'; // End proficiency group
        }
        
        // Tool Proficiencies
        if (traits.toolProf) {
          html += '<div class="proficiency-group">';
          html += '<h4>Tool Proficiencies</h4>';
          html += '<div class="proficiency-list">';
          
          if (traits.toolProf.value && traits.toolProf.value.length > 0) {
            traits.toolProf.value.forEach(tool => {
              html += `<div class="proficiency-item">${tool}</div>`;
            });
          } else {
            html += '<div class="proficiency-item">None</div>';
          }
          
          html += '</div>'; // End proficiency list
          html += '</div>'; // End proficiency group
        }
        
        // Weapon Proficiencies
        if (traits.weaponProf) {
          html += '<div class="proficiency-group">';
          html += '<h4>Weapon Proficiencies</h4>';
          html += '<div class="proficiency-list">';
          
          if (traits.weaponProf.value && traits.weaponProf.value.length > 0) {
            traits.weaponProf.value.forEach(weapon => {
              html += `<div class="proficiency-item">${weapon}</div>`;
            });
          } else {
            html += '<div class="proficiency-item">None</div>';
          }
          
          html += '</div>'; // End proficiency list
          html += '</div>'; // End proficiency group
        }
        
        // Armor Proficiencies
        if (traits.armorProf) {
          html += '<div class="proficiency-group">';
          html += '<h4>Armor Proficiencies</h4>';
          html += '<div class="proficiency-list">';
          
          if (traits.armorProf.value && traits.armorProf.value.length > 0) {
            traits.armorProf.value.forEach(armor => {
              html += `<div class="proficiency-item">${armor}</div>`;
            });
          } else {
            html += '<div class="proficiency-item">None</div>';
          }
          
          html += '</div>'; // End proficiency list
          html += '</div>'; // End proficiency group
        }
        
        html += '</div>'; // End proficiencies section
      }
      
      // Currency & Wealth
      if (characterSheet.system?.currency) {
        html += '<div class="currency-section">';
        html += '<h3>Currency</h3>';
        html += '<div class="currency-container">';
        
        const currency = characterSheet.system.currency;
        const currencyTypes = [
          { key: 'pp', name: 'Platinum' },
          { key: 'gp', name: 'Gold' },
          { key: 'ep', name: 'Electrum' },
          { key: 'sp', name: 'Silver' },
          { key: 'cp', name: 'Copper' }
        ];
        
        currencyTypes.forEach(type => {
          html += `
            <div class="currency-item">
              <span class="currency-name">${type.name}</span>
              <span class="currency-value">${currency[type.key] || 0}</span>
            </div>
          `;
        });
        
        html += '</div>'; // End currency container
        html += '</div>'; // End currency section
      }
      
      // Equipment Section - EXPANDED
      html += '<div class="equipment-section">';
      html += '<h3>Equipment</h3>';
      html += '<div class="equipment-list">';
      
      if (characterSheet.items) {
        // Group equipment by type
        const equipment = characterSheet.items.filter(item => 
          ['weapon', 'equipment', 'tool', 'consumable', 'container', 'backpack', 'loot'].includes(item.type)
        );
        
        if (equipment.length === 0) {
          html += '<div class="no-items">No equipment found</div>';
        } else {
          // Sort equipment by type and name
          equipment
            .sort((a, b) => {
              // Ensure type and name are strings
              const typeA = String(a.type || '');
              const typeB = String(b.type || '');
              const nameA = String(a.name || '');
              const nameB = String(b.name || '');
              
              if (typeA === typeB) return nameA.localeCompare(nameB);
              return typeA.localeCompare(typeB);
            })
            .forEach(item => {
              const itemType = item.type ? String(item.type).charAt(0).toUpperCase() + String(item.type).slice(1) : 'Item';
              const quantity = item.system?.quantity ? ` (×${item.system.quantity})` : '';
              const weight = item.system?.weight ? ` - ${item.system.weight} lbs` : '';
              
              html += `
                <div class="equipment-item">
                  <div class="equipment-name">${item.name || 'Unknown Item'}${quantity}</div>
                  <div class="equipment-type">${itemType}${weight}</div>
                  <div class="equipment-description">
                    ${item.system?.description?.value 
                      ? `<div>${item.system.description.value.replace(/<\/?[^>]+(>|$)/g, " ")}</div>`
                      : '<p>No description available</p>'
                    }
                  </div>
                `;
              
              // Add weapon-specific properties
              if (item.type === 'weapon' && item.system) {
                const weapon = item.system;
                html += '<div class="weapon-properties">';
                
                if (weapon.damage) {
                  html += `<div class="weapon-damage">Damage: ${weapon.damage.parts?.[0]?.[0] || ''} ${weapon.damage.parts?.[0]?.[1] || ''}</div>`;
                }
                
                if (weapon.attackBonus) {
                  html += `<div class="weapon-attack">Attack Bonus: ${weapon.attackBonus}</div>`;
                }
                
                if (weapon.properties) {
                  const props = Object.entries(weapon.properties)
                    .filter(([, value]) => value === true)
                    .map(([key]) => key ? key.charAt(0).toUpperCase() + key.slice(1) : '')
                    .join(', ');
                    
                  if (props) {
                    html += `<div class="weapon-props">Properties: ${props}</div>`;
                  }
                }
                
                if (weapon.range) {
                  html += `<div class="weapon-range">Range: ${weapon.range.value || 0}/${weapon.range.long || 0} ft</div>`;
                }
                
                html += '</div>'; // End weapon properties
              }
              
              html += '</div>'; // End equipment item
            });
        }
      } else {
        html += '<div class="no-items">No equipment found</div>';
      }
      
      html += '</div>'; // End equipment list
      html += '</div>'; // End equipment section
      
      // Features & Traits Section - EXPANDED
      html += '<div class="features-section">';
      html += '<h3>Features & Traits</h3>';
      html += '<div class="features-list">';
      
      if (characterSheet.items) {
        const features = characterSheet.items.filter(item => 
          item.type === 'feat' || item.type === 'feature'
        );
        
        if (features.length === 0) {
          html += '<div class="no-items">No features found</div>';
        } else {
          // Sort by source (class, race, etc.) - FIX sorting to handle non-string values
          features
            .sort((a, b) => {
              // Ensure sourceA and sourceB are strings
              const sourceA = String(a.system?.source || '');
              const sourceB = String(b.system?.source || '');
              if (sourceA === sourceB) return String(a.name || '').localeCompare(String(b.name || ''));
              return sourceA.localeCompare(sourceB);
            })
            .forEach(item => {
              const source = item.system?.source ? ` (${item.system.source})` : '';
              
              html += `
                <div class="feature-item">
                  <div class="feature-name">${item.name}${source}</div>
                  <div class="feature-description">
                    ${item.system?.description?.value 
                      ? `<div>${item.system.description.value.replace(/<\/?[^>]+(>|$)/g, " ")}</div>`
                      : '<p>No description available</p>'
                    }
                  </div>
                </div>
              `;
            });
        }
      } else {
        html += '<div class="no-items">No features found</div>';
      }
      
      html += '</div>'; // End features list
      html += '</div>'; // End features section
      
      // Spells Section - EXPANDED with spell slot tracking and organized by level
      if (characterSheet.items && characterSheet.items.some(item => item.type === 'spell')) {
        html += '<div class="spells-section">';
        html += '<h3>Spells</h3>';
        
        // Spellcasting Ability
        if (characterSheet.system?.attributes?.spellcasting) {
          const spellAbility = characterSheet.system.attributes.spellcasting.ability;
          if (spellAbility) {
            html += `<div class="spellcasting-ability">Spellcasting Ability: ${spellAbility.toUpperCase()}</div>`;
          }
        }
        
        // Spell Slots
        if (characterSheet.system?.spells) {
          html += '<div class="spell-slots-container">';
          
          // Display spell slots by level
          for (let level = 1; level <= 9; level++) {
            const slotKey = `spell${level}`;
            if (characterSheet.system.spells[slotKey]) {
              const slots = characterSheet.system.spells[slotKey];
              html += `
                <div class="spell-slot-level">
                  <div class="slot-level">Level ${level}</div>
                  <div class="slot-values">
                    <span class="slots-used">${slots.value || 0}</span>
                    <span class="slots-separator">/</span>
                    <span class="slots-total">${slots.max || 0}</span>
                  </div>
                </div>
              `;
            }
          }
          
          html += '</div>'; // End spell slots container
        }
        
        // Group spells by level
        html += '<div class="spell-list-by-level">';
        
        // Initialize an array to hold spells by level
        const spellsByLevel = {};
        for (let i = 0; i <= 9; i++) {
          spellsByLevel[i] = [];
        }
        
        // Sort spells into level buckets
        characterSheet.items
          .filter(item => item.type === 'spell')
          .forEach(spell => {
            const level = spell.system?.level || 0;
            spellsByLevel[level].push(spell);
          });
        
        // Create a section for each level that has spells
        for (let level = 0; level <= 9; level++) {
          if (spellsByLevel[level].length > 0) {
            const levelName = level === 0 ? 'Cantrips' : `Level ${level} Spells`;
            
            html += `<div class="spell-level-group">`;
            html += `<h4 class="spell-level-header">${levelName}</h4>`;
            
            // Sort spells alphabetically within each level
            spellsByLevel[level]
              .sort((a, b) => String(a.name || '').localeCompare(String(b.name || '')))
              .forEach(spell => {
                // Get additional spell details
                const prepared = spell.system?.preparation?.prepared ? ' (Prepared)' : '';
                const school = spell.system?.school ? ` - ${spell.system.school}` : '';
                const castTime = spell.system?.activation?.type ? ` - ${spell.system.activation.type}` : '';
                
                html += `
                  <div class="spell-item">
                    <div class="spell-name">${spell.name || 'Unknown Spell'}${prepared}</div>
                    <div class="spell-meta">${castTime}${school}</div>
                    <div class="spell-description">
                      ${spell.system?.description?.value 
                        ? `<div>${spell.system.description.value.replace(/<\/?[^>]+(>|$)/g, " ")}</div>`
                        : '<p>No description available</p>'
                      }
                    </div>
                  </div>
                `;
              });
            
            html += `</div>`; // End spell level group
          }
        }
        
        html += '</div>'; // End spell list by level
        html += '</div>'; // End spells section
      }
      
      // Add a bottom section for notes or any other information
      if (characterSheet.system?.details?.biography) {
        html += '<div class="biography-section">';
        html += '<h3>Biography & Notes</h3>';
        
        const bio = characterSheet.system.details.biography;
        
        // Include all biography fields if they exist
        if (bio.value) {
          html += `
            <div class="biography-entry">
              <h4>Biography</h4>
              <div class="biography-text">${bio.value.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
            </div>
          `;
        }
        
        if (bio.appearance) {
          html += `
            <div class="biography-entry">
              <h4>Appearance</h4>
              <div class="biography-text">${bio.appearance.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
            </div>
          `;
        }
        
        if (bio.trait) {
          html += `
            <div class="biography-entry">
              <h4>Personality Traits</h4>
              <div class="biography-text">${bio.trait.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
            </div>
          `;
        }
        
        if (bio.ideal) {
          html += `
            <div class="biography-entry">
              <h4>Ideals</h4>
              <div class="biography-text">${bio.ideal.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
            </div>
          `;
        }
        
        if (bio.bond) {
          html += `
            <div class="biography-entry">
              <h4>Bonds</h4>
              <div class="biography-text">${bio.bond.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
            </div>
          `;
        }
        
        if (bio.flaw) {
          html += `
            <div class="biography-entry">
              <h4>Flaws</h4>
              <div class="biography-text">${bio.flaw.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
            </div>
          `;
        }
        
        html += '</div>'; // End biography section
      }
      
      return html;
    };

    return (
      <div className="character-sheet-content">
        <div 
          id="raw-character-sheet"
          dangerouslySetInnerHTML={{ __html: renderCharacterContent() }} 
        />
        <div className="character-sheet-refresh">
          <button 
            className="refresh-button" 
            onClick={refreshCharacterSheet}
            disabled={isLoadingCharacter}
          >
            {isLoadingCharacter ? 'Refreshing...' : 'Refresh Character Sheet'}
          </button>
        </div>
      </div>
    );
  };
  
  // Handle image click to open in modal
  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  // Close the image modal
  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  // Main render
  return (
    <div className="adventurer-dashboard">
      <div className="dashboard-header">
        <h1>Adventurer Dashboard</h1>
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </div>
      
      <div className="dashboard-tabs">
        <button 
          className={`tab-button ${activeTab === 'overview' ? 'active' : ''}`}
          onClick={() => handleTabClick('overview')}
        >
          Overview
        </button>
        <button 
          className={`tab-button ${activeTab === 'character' ? 'active' : ''}`}
          onClick={() => handleTabClick('character')}
        >
          Character Sheet
        </button>
        <button 
          className={`tab-button ${activeTab === 'gallery' ? 'active' : ''}`}
          onClick={() => handleTabClick('gallery')}
        >
          Gallery
        </button>
        <button 
          className={`tab-button ${activeTab === 'profile' ? 'active' : ''}`}
          onClick={() => handleTabClick('profile')}
        >
          Profile
        </button>
      </div>
      
      <div className="tab-content">
        {activeTab === 'overview' && (
          <div className="overview-tab">
            <h3>Welcome, {currentUser?.displayName || currentUser?.email || 'Adventurer'}</h3>
            
            <div className="gm-messages">
              <h4>Messages from the Game Master</h4>
              {messages && messages.length > 0 ? (
                <div className="message-list">
                  {messages.map((message, index) => (
                    <div key={index} className="gm-message">
                      <div className="message-header">
                        <span className="message-from">From: {message.from}</span>
                        <span className="message-date">{formatDate(message.date)}</span>
                      </div>
                      <div 
                        className="message-content"
                        dangerouslySetInnerHTML={renderHtml(message.content)}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="no-messages">
                  <p>No messages from the GM yet.</p>
                </div>
              )}
            </div>
            
            <div className="last-session">
              <h4>Last Session Recap</h4>
              {lastSession ? (
                <div className="session-recap">
                  <div 
                    className="message-content"
                    dangerouslySetInnerHTML={renderHtml(lastSession.content)}
                  />
                  <span className="message-date">{formatDate(lastSession.date)}</span>
                </div>
              ) : (
                <div className="no-recap">
                  <p>No session recap available.</p>
                </div>
              )}
            </div>
          </div>
        )}
        
        {activeTab === 'gallery' && (
          <div className="gallery-tab">
            <h3>Image Gallery</h3>
            
            {isLoadingGallery ? (
              <div className="loading-gallery">
                <p>Loading gallery...</p>
              </div>
            ) : galleryError ? (
              <div className="gallery-error">
                <p>{galleryError}</p>
                <button onClick={currentFolder ? () => fetchFolderImages(currentFolder.category, currentFolder.path) : fetchGalleryCategories} className="retry-button">
                  Retry
                </button>
              </div>
            ) : currentFolder ? (
              // Show images in the current folder
              <>
                <div className="gallery-header">
                  <button className="back-button" onClick={handleBackToFolders}>
                    &larr; Back to Categories
                  </button>
                  <h4>{currentFolder.category.charAt(0).toUpperCase() + currentFolder.category.slice(1)} / {currentFolder.path}</h4>
                </div>
                
                {galleryImages.length === 0 ? (
                  <div className="no-images">
                    <p>No images found in this folder.</p>
                  </div>
                ) : (
                  <div className="gallery-grid">
                    {galleryImages.map((image, index) => {
                      console.log(`Rendering image ${index}:`, image.url);
                      return (
                        <div 
                          key={`image-${index}`} 
                          className="gallery-item"
                          onClick={() => handleImageClick(image)}
                        >
                          <img 
                            src={image.url} 
                            alt={image.name} 
                            onLoad={() => console.log(`Image loaded successfully: ${image.url}`)}
                            onError={(e) => {
                              console.error(`Failed to load image: ${image.url}`);
                              e.target.src = '/placeholder-image.svg';
                              e.target.alt = 'Image not found';
                            }} 
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            ) : (
              // Show categories and their folders
              galleryCategories.length === 0 ? (
                <div className="no-folders">
                  <p>No categories available in the gallery.</p>
                </div>
              ) : (
                <>
                  <div className="gallery-categories">
                    {/* Display each category with its folders */}
                    {galleryCategories.map(category => {
                      if (category.folders.length === 0) return null;
                      
                      return (
                        <div key={category.path} className="gallery-category">
                          <h4>{category.name}</h4>
                          <div className="folders-grid">
                            {category.folders.map((folder, index) => (
                              <div 
                                key={`folder-${index}`} 
                                className="folder-item"
                                onClick={() => handleFolderClick(category.path, folder)}
                              >
                                <div className="folder-icon">
                                  <i className="folder-symbol">📁</i>
                                </div>
                                <div className="folder-name">{folder.name}</div>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )
            )}
            
            {selectedImage && (
              <div className="image-modal-overlay" onClick={handleCloseModal}>
                <div className="image-modal" onClick={e => e.stopPropagation()}>
                  <div className="modal-header">
                    <h3>{selectedImage.name}</h3>
                    <div className="modal-actions">
                      <a 
                        className="download-button" 
                        href={selectedImage.url} 
                        download={selectedImage.name}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download
                      </a>
                      <button className="close-button" onClick={handleCloseModal}>
                        Close
                      </button>
                    </div>
                  </div>
                  <div className="modal-body">
                    <img 
                      src={selectedImage.url} 
                      alt={selectedImage.name} 
                      onError={(e) => {
                        console.error(`Failed to load image in modal: ${selectedImage.url}`);
                        e.target.src = '/placeholder-image.svg';
                        e.target.alt = 'Image not found';
                      }} 
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'character' && (
          <div className="character-sheet-tab">
            {renderCharacterSheet()}
          </div>
        )}
        
        {activeTab === 'profile' && (
          <div className="profile-tab">
            <h3>Your Profile</h3>
            
            <div className="avatar-section">
              <div className="current-avatar">
                {avatarUrl ? (
                  <img src={avatarUrl} alt="Your avatar" />
                ) : (
                  <div className="avatar-placeholder">
                    <span>{currentUser?.displayName?.[0] || currentUser?.email?.[0] || '?'}</span>
                  </div>
                )}
              </div>
              
              <div className="avatar-upload">
                <h4>Update Avatar</h4>
                <p>Upload a new avatar image (max 2MB).</p>
                
                <label className="upload-button">
                  Choose Image
                  <input 
                    type="file" 
                    accept="image/*" 
                    onChange={handleAvatarUpload} 
                    disabled={isUploading}
                  />
                </label>
                
                {isUploading && <div className="loading-spinner"></div>}
                {uploadError && <div className="upload-error">{uploadError}</div>}
              </div>
            </div>
            
            <div className="profile-info">
              <div className="info-item">
                <div className="info-label">Name</div>
                <div className="info-value">{currentUser?.displayName || 'Not set'}</div>
              </div>
              
              <div className="info-item">
                <div className="info-label">Email</div>
                <div className="info-value">{currentUser?.email}</div>
              </div>
              
              <div className="info-item">
                <div className="info-label">Role</div>
                <div className="info-value role-adventurer">Adventurer</div>
              </div>
              
              <div className="info-item">
                <div className="info-label">Member Since</div>
                <div className="info-value">{formatDate(currentUser?.createdAt)}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdventurerDashboard; 